import { useState, useMemo } from 'react';

const usePagination = (data) => {
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentData, setCurrentData] = useState([]);
  const [itemsPerPage,setItemsPerPage]=useState(10)

  // useEffect(() => {
  //   const start = (currentPage - 1) * itemsPerPage;
  //   const end = start + itemsPerPage;
  //   if(data?.length)
  //   setCurrentData(data.slice(start, end));
  // }, [currentPage, data, itemsPerPage]);
  
   //optmize data
  const currentData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    if(data?.length) 
     return data.slice(start, end);
  }, [currentPage, itemsPerPage, data]);

  const totalPages = Math.ceil(data?.length / itemsPerPage);


  return { currentData, currentPage, totalPages, setCurrentPage ,setItemsPerPage };
};

export default usePagination;
