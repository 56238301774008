/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import CollapsibleTable from '../../../../components/ui/CollapsibleTable';
import { Paper, Box} from '@mui/material';
import { apiURL } from '../../../../services/apiUrlConfig';
import authorization from '../../../../utils/authorization';
import util from 'util'
import { useDispatch, useSelector } from "react-redux";
import { getOrderManagementAct, resetStatus } from "../../../../saga/saga/orderManagementActSaga";
import { Skeleton } from '@mui/material';
import { Table, TableBody, TableCell, TableRow, TablePagination } from '@mui/material';

const headCells = [
  {
    id: "cropname",
    numeric: false,
    disablePadding: true,
    label: "Crop",
    minWidth: 250,
    isInputBox: false,
    isLast: false,
    isMaxWidthApply: true,
  },
  {
    id: "productname",
    numeric: false,
    disablePadding: false,
    label: "Variety",
    minWidth: 200,
    isInputBox: false,
    isLast: false,
    isMaxWidthApply: true,

  },
  {
    id: "productuom__c",
    numeric: true,
    disablePadding: true,
    label: "Unit",
    minWidth: 180,
    isInputBox: false,
    isLast: false,
    isMaxWidthApply: true,

  },
  {
    id: "quantity__c",
    numeric: true,
    disablePadding: false,
    label: "Order Quantity",
    minWidth: 250,
    isInputBox: false,
    isLast: false,
    isMaxWidthApply: true,

  },
  {
    id: "requested_delivery_date__c",
    numeric: true,
    disablePadding: false,
    label: "Delivery Date",
    minWidth: 180,
    isInputBox: false,
    isLast: true,
    isMaxWidthApply: true,
    type:'date'

  },  
];

const collapseHeaderCells=[
  { label: "Priming", id: "primed__c", minWidth: 230, userInteration: false,  isMaxWidthApply: true },
  { label: "Treatment", id: "ord_treatment__c", minWidth: 250, userInteration: false,  isMaxWidthApply: true  },
  { label: "Packaging Type", id: "ord_packaging__c", minWidth: 300, userInteration: false, isMaxWidthApply: true },
  { label: "Comments", id: "customer_requirements__c", minWidth: 300, userInteration: false, isMaxWidthApply: false },
]


export const LoadWhile = () => {
  return  (
    [...Array(4)].map((item, itemIndex) => {
      return ( 
        <>      
            <TableRow >
              <TableCell sx={{ border: 0 }} >
                <Box display="flex" justifyContent="space-between" sx={{ 
                   alignItems:'center',
                  width: '100%',
                  height: '100%',                 
                  }} 
                  >
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{ 
                      height: 50,
                       width: "100%",
                        backgroundColor: "#F2F2F2" }}
                  />
                    </Box>               
              </TableCell>
            </TableRow> 
            
           </>     
      );
    })
  )
};




const TimeStatus = ({ currentstatus, value }) => {
  if(currentstatus.split(" ").join("") === 'OnTime') {
    return <>&nbsp;&nbsp;<span style={{ color: 'green', fontSize: '20px' }}>●</span>  {currentstatus}</>
  } else {
    return <>&nbsp;&nbsp;<span style={{ color: 'orange', fontSize: '20px' }}>●</span> {currentstatus}</>
  }  
}

const CollapsibleView = (props) => {
  return (
    <>
     <CollapsibleTable
                  statusLoad={props.statusLoad}
                  rows={props.rows}
                  headCells={props.headCells}
                  getFilterData={() => { }}
                  isPagination={props.isPagination}
                  getAPICall={() => { }}
                  collapseHeaderCells={props.collapseHeaderCells}
                  getSelectedRowTable={() => { }}
                />
    </>
  )
}

const OrderTableView = (props) => { 
  const [collapseData, setCollapseData] = useState(false)

  const arrowUpDown = (key = null) => {
    if(key !== null){
      setCollapseData(true);
      props.arrowUpDown(key);
    }else{
      setCollapseData(false);
      props.arrowUpDown();
    }
  }

  const orderManagementTableBody = () => {

     return props.statusLoad ? (
     (props.rows?.length  > 0 && props.headCells?.length > 0) ? 
     props.rows?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((item, key) => {
         return <><TableRow 
           key={key} >
             <TableCell sx={{ border: 0 }}    >
             <Box display="flex" justifyContent="space-between" sx={{
                alignItems:'center',
               background: '#EBF0F2',
               width: '100%',
               height: '100%',
               padding: '10px',
               }}>
                 <span><Typography>{item.name} <TimeStatus currentstatus={item.currentstatus} value={item.currentdelaydays} />  </Typography></span>
                 <span>{props.arrow && key === props.openKey ? <KeyboardArrowUpOutlinedIcon sx={{cursor:'pointer' }}  onClick={() => arrowUpDown()} />: <KeyboardArrowDownOutlinedIcon sx={{cursor:'pointer' }} onClick={() => arrowUpDown(key)} />  }</span>
               </Box>
             </TableCell>            
           </TableRow>
          { key === props.openKey && <TableRow >
             <TableCell align='right'  sx={{ border: 0 }} >              
           {collapseData && <CollapsibleView statusLoad = {false}
             rows={ item?.lineitems}
             headCells={props.headCells}
             getFilterData={() => { }}
             isPagination={false}
             getAPICall={() => { }}
             collapseHeaderCells={props.collapseHeaderCells}
             getSelectedRowTable={() => { }}
             /> 
           }
             </TableCell> 
             </TableRow> }  
            
            
       </>
     }): 
     <>
     <TableRow>
     <TableCell  sx={{ border: 0, textAlign: "center" }}>
     <Box  sx={{
       background: '#EBF0F2',         
       padding: '10px',          
       }}>
       No records found!
       </Box>
     </TableCell>
   </TableRow>
   </>
       )
     : <LoadWhile/>
     
     

  }

  return(
    <>
    <Table aria-label="simple table">        
        <TableBody> 
        {orderManagementTableBody()}
        </TableBody>
      </Table>
    </>
  )

}
export const OrderManagement = () => {
  const [rows, setRows] = useState([])
  const authUser = authorization.getAuthUser();
   const [statusLoad, setStatusLoad] = useState(true);
  const [arrow,setArrow] = useState(true);
  const [openKey, setOpenKey] = useState();
  const dispatch = useDispatch();
  const getOrderManagementActivities = useSelector((state) => state.OrderManagementAct?.actOrderManagementList);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [submitby, setSubmitby] = useState(0)
  const getConditionalValues = useSelector(
    (state) => state.contracts.condsAndUncondsObj
  );
 
  const handleChangePage = (event, newPage) => {
    setOpenKey('')
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setOpenKey('')
    setRowsPerPage(parseInt(event.target.value, 13));
    setPage(0);
  };

  const getLoadData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const condUrl = util.format(activities.getOrderManagementActivities, authUser?.id, getConditionalValues?.contractId);
    const queryParams = `?pppstage=${"ACTIVITIES"}`
    dispatch(getOrderManagementAct(condUrl + queryParams));
  };

  useEffect(() => {
    setStatusLoad(false)
    getLoadData();
  }, []);

  useEffect(() => {
    getOrderManagementActivities?.body?.orders?.length >= 0 && setStatusLoad(true)
    setRows(getOrderManagementActivities?.body?.orders); 
    setSubmitby(getOrderManagementActivities?.body?.submitby)  
  }, [getOrderManagementActivities]);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
    }
  }, [])

 

  const arrowUpDown = (key = null) => {
    if(key !== null){
      setOpenKey(key);
      setArrow(true);
    }else{
      setOpenKey('');
      setArrow(false);
    }
  }

  return (
    <Fragment>
      {submitby > 0 && <Box sx={{ mb: 0.5 }}>Orders need to be submitted at least <strong> {submitby > 1 ? `${submitby} months` : `${submitby} month` } </strong>  in advance of the “invoicing” date</Box> }
       <Box display="flex" flexDirection="column" gap={1.5}>
       <Paper elevation={0} sx={{ 
        width: "100%"
          }}>     
        <Box sx={{  
          m: 3,
        maxHeight:343, 
        overflow: 'auto'
         }}>
        <OrderTableView 
            rows = {rows}
            headCells= {headCells}
            collapseHeaderCells ={collapseHeaderCells}
            arrow= {arrow}
            openKey = {openKey}           
            arrowUpDown = {arrowUpDown}
            page = {page} 
            rowsPerPage = {rowsPerPage} 
            statusLoad = {statusLoad}
        />  
       
      </Box>  
      <Box>
      { Number(rows?.length) > 10 && <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={Number(rows?.length)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> }
      </Box>
    </Paper>
   
    </Box>
    </Fragment>
  )
}


