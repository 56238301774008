/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Fragment, useState } from "react";
//material-ui
import { CardActionArea, CardContent, FormControl, Grid, Typography, Box } from "@mui/material";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PaymentsIcon from "@mui/icons-material/Payments";
import Authorization from "../../../utils/authorization";
import Loader from "../../../components/ui/Loader";
import {  getContractActionType } from "../../../store/reducers/common";
import { useSelector, useDispatch } from "react-redux";
import Accounts from "./Accounts";
import Card from "@mui/material/Card";
import MainCard from "../../../components/MainCard";
import TimePerformance from "./TimePerformance";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Link from "@mui/material/Link";
import ReactSelect from "../../../components/third-party/ReactSelect";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CONTRACT_YEAR } from "../../../constants";

import "./dashboard.scss";

const shineStyles = {
  position: "relative",
  "&:hover:after": {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0)",
    "-webkit-transition": "all 0.4s ease-in-out",
    "-moz-transition": "all 0.4s ease-in-out",
    transition: "all 0.4s ease-in-out",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: 0,
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.4)",
    "-webkit-transition": "none",
    "-moz-transition": "none",
    transition: "none",
  },
};

const PPPDashboard = () => {
  const currentContractYear = CONTRACT_YEAR;
  const oldDataYear = 2025;
  let years = [];
  if (currentContractYear > oldDataYear) {
    years = Array.from({ length: currentContractYear - oldDataYear + 1 }, (_, index) => currentContractYear - index);
  } else {
    years = [oldDataYear];
  }
  const [isLoader, SetLoader] = useState(false);
  const [accountsYear, setYearAccounts] = useState(currentContractYear);
  const [analyticsYear, setYearAnalytics] = useState(currentContractYear);
  const { countryCode } = useSelector((state) => state.common);
  const authUser = Authorization.getAuthUser();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callbackLoader = (isLoader) => {
    SetLoader(isLoader);
  };

  const dropDownValues = years.map((year, index) => ({
    value: year,
    label: year,
  }));

  const handleChange = (option) => {
    setYearAccounts(option?.value);
  };
  const handleChangeAnalytics = (option) => {
    setYearAnalytics(option?.value);
  };
  const navigateToContract = () => {
    dispatch(getContractActionType({ type: "CREATE", contratId: "", step: "" }));
    navigate("/contracts");
  };
  const navigateToActivities = () => {
     navigate("/accounts");
  };
  return (
    <Fragment>
      {isLoader && <Loader />}
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {/* <Link component={RouterLink} to='/contracts'> */}
          <Card
            className="top-card-contacts"
            sx={{
              ...shineStyles,
              width: "100%",
              backgroundColor: "#00617f",
              borderRadius: "10px",
              ":hover": {
                boxShadow: "10px 10px 20px #00617f",
              },
            }}
          >
            <CardActionArea onClick={() => navigateToContract()}>
              <CardContent className="top-card-content">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReceiptLongIcon
                      sx={{
                        color: "white",
                        opacity: 1,
                        height: "1.5em",
                        width: "1.5em",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "18px",
                        color: "white",
                        marginTop: "1vh",
                      }}
                    >
                      Contracts/Targets
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
          {/* </Link> */}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            className="top-card-activities"
            sx={{
              ...shineStyles,
              width: "100%",
              backgroundColor: "#2b6636",
              borderRadius: "10px",
              position: "relative",
              cursor:"pointer",
              "&:hover": {
                boxShadow: "10px 10px 20px #2b6636",
              },
            }}
            key="actvities"
            onClick={()=>navigateToActivities()}
          >
            <CardActionArea>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <MonitorHeartOutlinedIcon
                      sx={{
                        color: "white",
                        opacity: 1,
                        height: "1.5em",
                        width: "1.5em",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "18px",
                        color: "white",
                        marginTop: "1vh",
                      }}
                    >
                      Activities
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            className="top-card-tracking"
            sx={{
              ...shineStyles,
              width: "100%",
              backgroundColor: "#624963",
              borderRadius: "10px",
              ":hover": {
                boxShadow: "10px 10px 20px #624963",
              },
            }}
          >
            <CardActionArea>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <QueryStatsIcon
                      sx={{
                        color: "white",
                        opacity: 1,
                        height: "1.5em",
                        width: "1.5em",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "18px",
                        color: "white",
                        marginTop: "1vh",
                      }}
                    >
                      Tracking
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            className="top-card-payout"
            sx={{
              ...shineStyles,
              width: "100%",
              backgroundColor: "#de0043",
              borderRadius: "10px",
              ":hover": {
                boxShadow: "10px 10px 20px #ba3030",
              },
            }}
          >
            <CardActionArea>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <PaymentsIcon
                      sx={{
                        color: "white",
                        opacity: 1,
                        height: "1.5em",
                        width: "1.5em",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "18px",
                        color: "white",
                        marginTop: "1vh",
                      }}
                    >
                      Payout
                    </Typography>
                  </div>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <MainCard title="" elevation={0} border={false} content={true} contentSX={{ p: 1 }} headerSX={{ p: 1, color: "#707070" }}>
            <div
              style={{
                display: "flex",
                margin: "8px 16px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>Analytics</div>
              <div>
                <FormControl>
                  <ReactSelect
                    name={"accounts"}
                    options={dropDownValues}
                    handleChange={(selectedOptions, e) => {
                      handleChangeAnalytics(selectedOptions);
                    }}
                    value={analyticsYear}
                    id="account-list"
                    labelPosition="top"
                    width={100}
                    className="acc-select"
                    defaultValue="List of accounts selected"
                  />
                </FormControl>
              </div>
            </div>
            <TimePerformance callbackLoader={callbackLoader} selectedCountryCode={countryCode} loggedUser={authUser} />
          </MainCard>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <MainCard elevation={0} border={false} content={true} contentSX={{ p: 1 }} headerSX={{ p: 1, color: "#707070" }}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={"8px 16px"}>
              <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>Contracts</div>
              <FormControl>
                <ReactSelect
                  name={"accounts"}
                  options={dropDownValues}
                  handleChange={(selectedOptions, e) => {
                    handleChange(selectedOptions);
                  }}
                  value={accountsYear}
                  id="account-list"
                  labelPosition="top"
                  width={100}
                  className="acc-select"
                  defaultValue="List of accounts selected"
                />
              </FormControl>
            </Box>

            <Accounts callbackLoader={callbackLoader} selectedCountryCode={countryCode} loggedUser={authUser} selectedYear={accountsYear} />
          </MainCard>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PPPDashboard;
