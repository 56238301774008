import { useState,Fragment } from "react";
import LeaderBoardTable from "./LeaderBoardTable";
import { ErrorMsg } from "../../utils/helper";
import { downloadFileFromURL } from "../../utils/helper";
import { getRequest } from "../../services/apiUtils";
import Loader from "../../components/ui/Loader";
import { openSnackbar } from "../../components/ui/Toastr";
import { apiURL } from "../../services/apiUrlConfig";
import Authorization from "../../utils/authorization";
import { useSelector } from "react-redux";

const columns = [
  {
    width: 25,
    label: "",
    dataKey: "serial",
  },
  {
    width: 120,
    label: "Country",
    dataKey: "country",
  },
  {
    width: 100,
    label: "Planned",
    dataKey: "createdloc",
    numeric: true,
  },
  {
    width: 100,
    label: "Completed",
    dataKey: "completedloc",
    numeric: true,
  },
];

const TrialLeader = ({ data }) => {
  const [isLoader, SetLoader] = useState(false);
  const { countryCode, selectedYear, selectedTrialExec } = useSelector((state) => state.common);
  const authUser = Authorization.getAuthUser();

  const handleDownload = () => {
    const { targetDashboard } = apiURL;
    SetLoader(true);
    getRequest({
      url: `${targetDashboard.getUserTrialLeaderBoard}/${authUser?.id}`,
      queryParams: {
        countryCode: countryCode === "ALL" ? null : countryCode,
        year: selectedYear || null,
        trialExecution: selectedTrialExec === "ALL" ? null : selectedTrialExec,
        download: true,
      },
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body;
        const resultURL = result?.data?.downloadUrl;
        if (resultURL) {
          downloadFileFromURL(resultURL);
          openSnackbar({ message: "Download has been started", variant: "success" });
        } else {
          openSnackbar({ message: "No records found!", variant: "warning" });
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  return (
    <Fragment>
      {isLoader && <Loader />}
      <LeaderBoardTable title="Trial Location Leaderboard" rows={data} headers={columns}  download={handleDownload} />
    </Fragment>
  );
};

export default TrialLeader;
