/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box  } from '@mui/material';
import { Table, TableBody, TableContainer, TableRow, } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { apiURL } from '../../../../services/apiUrlConfig';
import authorization from '../../../../utils/authorization';
import util from 'util'
import { useDispatch, useSelector } from "react-redux";
import { getOrderManagementAct as getSalesVolumeAct, resetStatus } from "../../../../saga/saga/orderManagementActSaga";
import { CONTRACT_YEAR } from '../../../../constants'
import { LoadWhile } from '../OrderManagement/OrderManagement'

const tierArray = ['base','silver','gold','platinum']

const headCells = [
  {
    id: "tiers",
    numeric: false,
    disablePadding: false,
    label: "Tiers",
    minWidth: 200,
    isInputBox: false,
    isLast: false,
  },
  {
    id: "netInvoicedPurchases",
    numeric: false,
    disablePadding: false,
    label: "Net Invoiced Purchases",
    minWidth: 120,
    isInputBox: false,
    isLast: false,

  },
  
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#00617F',
      color: theme.palette.common.white,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 11,
      fontWeight: 100
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      paddingTop: 10,
      paddingBottom: 9,
      fontWeight: 100
  },
}));
const SalesVolumeRow = (props) => {
    return(
      <>
      <TableRow
      key={props.index}
      sx={{ 
        border: 0, 
        background: props.index===props.highlight?'#EBF0F2':''}}
      >
      <StyledTableCell key={1} style={{ width: '100%' }} align="left">
          <Box style={{ display:'flex', flexDirection:'row', width:'100%' }}>
              <Box style={{ height:'50', width:'50%'}}>
                   {Object.values(props.row)[0] !== '' ? Object.values(props.row)[0] : '-' }
              </Box>
              <Box style={{ height:'50', width:'50%'}}>
                      { Object.values(props.row)[1] !== '' ? Object.values(props.row)[1] : '0' }
              </Box>
          </Box>
      </StyledTableCell>                               
  </TableRow>
  </>
    )
}
  
const SalesVolumeTable = (props) => {
    return(
        <>
         <TableContainer style={{ overflow: 'auto', height: 370 }}  component={Paper}>
                <Table stickyHeader aria-label="sticky table">                   
                    <TableBody>
                    <TableRow
                               
                                sx={{ 
                                  border: 0, 
                                  background: '#00617F',
                                 
                                 }}
                            >
                     <StyledTableCell key={1} style={{ width: '100%'  }} align="left">
                                    <Box style={{ display:'flex', flexDirection:'row', width:'100%',  color: '#fff' }}>
                                        <Box style={{ height:'50', width:'50%'}}>
                                        Tiers
                                        </Box>
                                        <Box style={{ height:'50', width:'50%'}}>
                                        Net Invoiced Purchases
                                        </Box>
                                    </Box>
                                </StyledTableCell>                              
                            </TableRow>
                            { (!props.statusLoad && props.status > 0 ) ?(
                        (props.rows?.length > 0 && props.headCells?.length > 0) ? 
                        props.rows.map((row, index) => (
                          <>
                            <SalesVolumeRow 
                            index = {index} 
                            highlight = {props.highlight}
                            row = {row}
                            />                           
                          </>
                        )) : 
                        <TableRow>
                        <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                            {" "}
                            No records found!
                        </TableCell>
                    </TableRow>
                            ) : 
                         <LoadWhile/>                   
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
const SalesVolume = () => {
  const [rows, setRows] = useState([])
  const [cysales, setCysales] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [statusLoad,setStatusLoad] = useState(true);
  const [highlight, setHighlight] = useState(0)
  const [status, setStatus] = useState(0)
  const authUser = authorization.getAuthUser();
  const dispatch = useDispatch();
  const getSalesVolumeActivitiesData = useSelector((state) => state.OrderManagementAct?.actOrderManagementList);
  const getConditionalValues = useSelector(
    (state) => state.contracts.condsAndUncondsObj
  );
  const getLoadData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const condUrl = util.format(activities.getSalesVolumeActivities, authUser?.id, getConditionalValues?.contractId);
    const queryParams = `?pppstage=${"ACTIVITIES"}`
    dispatch(getSalesVolumeAct(condUrl+queryParams));
  };

  useEffect(() => {
    getLoadData();   
  }, []);

  useEffect(() => {
    getSalesVolumeActivitiesData?.body &&  keyAsColumns(getSalesVolumeActivitiesData?.body)
    setStatus(getSalesVolumeActivitiesData?.status || 0)
  }, [getSalesVolumeActivitiesData]);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
   }
}, [])

const keyAsColumns = (val) => {   
    let keys = Object.keys(val)
    let rows = []
    for(let i = 0; i < keys.length; i++){
      if(['base','silver','gold','platinum'].includes(keys[i])){      
      rows = [...rows, {
        'tiers': keys[i].charAt(0).toUpperCase() + keys[i].slice(1),
        'netInvoicedPurchases' :  (i>0 && keys[i] !== 'base' )  ?
      (keys[i] !== 'platinum') ? val.currency+' ' + (val[keys[i-1]] + 1).toLocaleString() +' - ' + val.currency+' '+ val[keys[i]].toLocaleString() : val.currency+' ' +(val[keys[i-1]]+1).toLocaleString() + ' +'
        : val.currency+' ' + 0 + ' - ' + val.currency+' ' +  val[keys[i]].toLocaleString()  }]
      }
    }   
    setRows( rows);   
    const getCysales = val.cysales && val.cysales.toLocaleString()
    setCysales((val.currency !== undefined && getCysales !== undefined)  ? val.currency+' '+getCysales : '')
    
    if(Object.keys(val)?.length){
      for (let index = 0; index < tierArray.length; index++) {
        const element = tierArray[index];
        const extraIndex = index===0?0:1
        if (val?.cysales <= val[element]+extraIndex && highlight === 0) {
          setHighlight(index);
          break; 
        }
        else if(!isNaN(val?.cysales)){
          setHighlight(tierArray?.length-1);
        }else{
          setHighlight(null)
        }
      }
    }
     setStatusLoad(false) 
  }

  return (
    <Fragment>

      <Box display="flex" flexDirection="column" gap={1.5}>       
      
        <Paper elevation={0} sx={{ width: "100%" }}>
            <Box sx={{  m: 3 }}>
        <Typography>Year: {CONTRACT_YEAR ? CONTRACT_YEAR-1 : '' }</Typography>
        <Typography sx={{  mb: 1 }}>Year-To-Date Net Invoiced Purchases: <strong>{cysales || 0 }</strong></Typography>
       
            <SalesVolumeTable 
            statusLoad = {statusLoad}
            rows = {rows}
            headCells = {headCells}
            highlight = {highlight}           
            status = {status}
            />
            </Box>
        </Paper>
      
      </Box>
    </Fragment>
  )
}


export default SalesVolume;