import { React, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Childs } from "../constants";
import { BrView } from "../pages/PPP/Activities/ActivityTypes/ActivityTypeView";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const common_tabs = ["Home", "Activities"];

export function dynoNavigate(Component) {
  const Activityalloc = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const activityClick = (selectedActDetails) => {
      if (props.enable) {
        const dynoRoute = props?.activitydetails;
        const crdData = location.state?.data;
        const rtpath = Childs[(selectedActDetails?.ActivityId).toUpperCase()];
        const titlepath = `${location?.pathname}/${rtpath}`;
        navigate(Childs[(selectedActDetails?.ActivityId).toUpperCase()], {
          state: {
            data: { ...crdData, ...dynoRoute },
            obj: [dynoRoute?.activityname],
            title: { ...location?.state?.title, [titlepath]: dynoRoute?.activitydisplayname },
          },
        });
      }
    };
    return <Component {...props} onClick={() => activityClick(props)} />;
  };
  return Activityalloc;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  border: "1px solid #ADD8E6",
  boxShadow: " 0px 2px 1px -1px #ADD8E6, 0px 1px 1px 0px #ADD8E6, 0px 1px 3px 0px #ADD8E6",
}));
export function enRouteActivity(Component) {
  const RouteDir = (props) => {
    const location = useLocation();
    let dpName = common_tabs.concat(location.state?.obj);
    const pathnames = location.pathname.split("/").filter((x) => x);
    return (
      <Fragment>
        <div>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <BrView crdData={location.state?.data} crdNames={location.state?.obj} dpName={dpName} paths={pathnames} />
            <Box>
              {pathnames?.length > 0 && pathnames.length > 2 && (
                <Box sx={{ width: 250 }}>
                  <Stack direction="row" flexWrap={"nowrap"}>
                    <Item>{location?.state?.title?.["/accounts/activities"] ?? ""} </Item>
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
          <Box style={{ fontSize: 13, letterSpacing: 0.3, paddingTop: 10, height: 40, width: "100%", color: "#323232", fontWeight: 600 }}>
            {location?.state?.title?.[location.pathname] ?? ""}
          </Box>
        </div>
        <Component {...props} />
      </Fragment>
    );
  };
  return RouteDir;
}
