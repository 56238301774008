import { createSlice } from "@reduxjs/toolkit";
const initialState = {
 brandRepresentList: [],
 brandRepresentLoading: false,
 brandRepresentError: false,
};

const brandRepresentActivities = createSlice({
  name: "brandRepresentActivities",
  initialState,
  reducers: {
    getBrandRepresent(state) {
      state.brandRepresentLoading = true;
      state.brandRepresentList = [];
    },
    getBrandRepresent_successful(state, action) {
      state.brandRepresentList = action.payload.body;
      state.brandRepresentLoading = false;
    },
    getBrandRepresent_error(state) {
      state.brandRepresentError = true;
    },
  },
});

export const { getBrandRepresent, getBrandRepresent_successful, getBrandRepresent_error } = brandRepresentActivities.actions;

export default brandRepresentActivities.reducer;
