import { useState, Fragment } from "react";
import LeaderBoardTable from "./LeaderBoardTable";
import { ErrorMsg } from "../../utils/helper";
import { downloadFileFromURL } from "../../utils/helper";
import { getRequest } from "../../services/apiUtils";
import Loader from "../../components/ui/Loader";
import { openSnackbar } from "../../components/ui/Toastr";
import { apiURL } from "../../services/apiUrlConfig";
import Authorization from "../../utils/authorization";
import { useSelector } from "react-redux";

const columns = [
  {
    width: 10,
    label: "",
    dataKey: "serial",
  },
  {
    width: 125,
    label: "Account Name",
    dataKey: "name",
  },
  {
    width: 100,
    label: "Trials Completed",
    dataKey: "trialCount",
    numeric: true,
  },
  {
    width: 100,
    label: "Assessments Done",
    dataKey: "assessmentCount",
    numeric: true,
  },
];
const UserTrialLeader = ({ data }) => {
  const [isLoader, SetLoader] = useState(false);
  const { countryCode, selectedYear, selectedTrialExec } = useSelector((state) => state.common);
  const authUser = Authorization.getAuthUser();

  const handleDownload = () => {
    const { targetDashboard } = apiURL;
    SetLoader(true);
    getRequest({
      url: `${targetDashboard.getUserTrialLeaderBoard}/${authUser?.id}`,
      queryParams: {
        countryCode: countryCode === "ALL" ? null : countryCode,
        year: selectedYear || null,
        trialExecution: selectedTrialExec === "ALL" ? null : selectedTrialExec,
        download: true,
      },
    })
      .then((response) => {
        SetLoader(false);
        const result = response?.body;
        const resultURL2 = result?.data?.downloadUrl2;
        if (resultURL2) {
          // const URLData = [resultURL, resultURL2];
          // for (let i = 0; i < URLData?.length; i++) {
          //   var frame = document.createElement("iframe");
          //   frame.src = URLData[i];
          //   document.body.appendChild(frame);
          // }
          // URLData.forEach((url) => downloadFileFromURL(url));
           downloadFileFromURL(resultURL2);
          openSnackbar({ message: "Download has been started", variant: "success" });
        } else {
          openSnackbar({ message: "No records found!", variant: "warning" });
        }
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  return (
    <Fragment>
      {isLoader && <Loader />}
      <LeaderBoardTable title="Partner Trial Leaderboard (external)" rows={data} headers={columns}  download={handleDownload} />
    </Fragment>
  );
};

export default UserTrialLeader;
