import {  useState } from "react";
import {  Box, styled, Pagination } from "@mui/material";
import ReactSelect from "../third-party/ReactSelect";


const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& > *": {
    marginTop: theme.spacing(2),
  },
  "& .Mui-selected": {
    background: "transparent linear-gradient(90deg, #03BCFB 0%, #025E7E 100%) 0% 0% no-repeat padding-box",
    color: "#FFFFFF",
  },
  "& button": {
    "[aria-label=Go to previous page]": {
      background: " #FFFFFF 0% 0% no-repeat padding-box !important",
      boxShadow: "0px 3px 6px #00000029",
      border: "0.5px solid #BFBFBF !important",
      borderRadius: "5px",
    },
    border: "1px solid rgba(0, 0, 0, 0.20)",
  },
}));
const rowPerPageOptions = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
];
const CusPagination = ({ count, page, handlePageChange, handleRowsPerChange,handleCurrentPage }) => {
  const itemsPerPage = 10;
  const [selectedValue, setSelectedValue] = useState(itemsPerPage);
  const handleSelect = (option) => {
    setSelectedValue(option?.value);
    handleRowsPerChange(option?.value);
    handleCurrentPage()
  
  };
  return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={1}>
        <ReactSelect
          name={"rowsperpage"}
          options={rowPerPageOptions}
          handleChange={(selectedOptions, e) => {
            handleSelect(selectedOptions);
          }}
          value={selectedValue}
          id="rowsperpage"
          label="Rows per page:"
          labelPosition="left"
          menuPlacementType="top"
          defaultValue={selectedValue}
        />

        <StyledPagination count={count} page={page} onChange={handlePageChange} variant="outlined" shape="rounded" />
      </Box>
  );
};

export default CusPagination;
