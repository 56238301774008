/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { getActivities,resetStatus } from "../../../../saga/saga/activitiesSaga";
import Authorization from "../../../../utils/authorization";
import util from "util";
import { apiURL } from "../../../../services/apiUrlConfig";
import { useDispatch, useSelector } from "react-redux";
import ActivityTypeView from "./ActivityTypeView";

export default function ActivityTypesModel() {
  const [activities, setActivities] = useState([]);
  const [dynoactivities, setdynoActivities] = useState([]);
  const getApi = apiURL.ppp.getActivities ?? "";
  const userDetaisl = Authorization.authUser;
  const activitisApi = util.format(getApi, userDetaisl.id);
  const dispatch = useDispatch();
  const activitiesList = useSelector((state) => state.activitiesSaga.activitiesList);
  const activitiesStatus = useSelector((state) => state.activitiesSaga.activitiesloading);
  const condsAndUncondsObj = useSelector((state) => state.contracts.condsAndUncondsObj);
  
  const activitiesSchema = (obj,available) => {
    return {
    'activitytypeid':obj?.id,
    'id':obj?.id,
    'activityname':obj?.name,
    'activitydisplayname':obj?.displayname,
    'isenabled':available
    }
  }

  const identifyActivities = (src) => {
    let filterActivitiesEnabled = []
    // eslint-disable-next-line
    src?.length>0&&src.map((item,_index) => {
     const available =  dynoactivities.filter((activitie)=>activitie?.activitytypeid === item?.id)
     filterActivitiesEnabled.push(activitiesSchema(item,available?.length>0))
    })
    return filterActivitiesEnabled
  }
  useEffect(() => {
   const actv =   identifyActivities(activitiesList)
   setActivities(actv)
  }, [activitiesList]);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
    }
}, [])

  useEffect(() => {
    const subactves = [...condsAndUncondsObj?.objeArr?.subActivities]
    subactves&&subactves.sort((a, b) => a.activitytypeid - b.activitytypeid);
    setdynoActivities(subactves??[]);
    dispatch(getActivities(activitisApi));
  }, [condsAndUncondsObj]);

  return (
    <Fragment>
      <ActivityTypeView loadWhie={activitiesStatus} AccTypes={activities} />
    </Fragment>
  );
}
