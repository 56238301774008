/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Fragment } from "react";
import { Typography } from "@mui/material";
import CollapsibleTable from "../../../../../components/ui/CollapsibleTable";
import MainCard from "../../../../../components/MainCard";
import { useLocation } from "react-router-dom";
import { onlineHeaders } from "../../../../../constants/OffAndOnline";
import { useSelector } from "react-redux";



const OnlineMarketingActivitiesTypes = () => {
  const [collapseContent, setCollapseContent] = useState([]);
  const [headerCells, setHeaderCells] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const { state } = useLocation();
  const getOnlineMarketingActivities = useSelector((state) => state.onlineMarketingAct?.onlineMAList);
  const getOnlineMarketingActivitiesStatus = useSelector((state) => state.onlineMarketingAct?.onlineMALoading);

  useEffect(() => {
    if (state?.onlineActType?.marketingactivitytypename) {
      const typeName = state?.onlineActType?.marketingactivitytypename?.toLowerCase();
      const result = onlineHeaders.find((onH) => onH.typeName === typeName);
      if (result?.collapseH?.length) {
        const updatedWidth = result?.collapseH.map((ch) => {
          return (ch = { ...ch, minWidth: (ch?.id === "description__c" ||ch?.id === "attachments")  ? 250 : 150 });
        });
        setCollapseContent(updatedWidth);
      }
      setHeaderCells(result?.headers);
      setRowsData(state?.onlineActType?.customerActivities);
    }
  }, []);
  useEffect(() => {
    if (!getOnlineMarketingActivitiesStatus) {
      if (getOnlineMarketingActivities.activitytypes?.length) {
        const typeName = state?.onlineActType?.marketingactivitytypename?.toLowerCase();
        const data = getOnlineMarketingActivities.activitytypes[0].activities;
        if (data?.length) {
          const filteredData = data.find((d) => d?.marketingactivitytypename?.toLowerCase() === typeName);
          setRowsData(filteredData?.customerActivities);
        }
      }
    }
  }, [getOnlineMarketingActivities, getOnlineMarketingActivitiesStatus]);

  const handleCallBack = (childData) => {};

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom>
        {state?.onlineActType?.marketingactivitytypename}
      </Typography>
      <MainCard
        elevation={0}
        border={false}
        content={true}
      >
        <CollapsibleTable
          statusLoad={false}
          rows={rowsData}
          headCells={headerCells}
          getFilterData={() => {}}
          isPagination={true}
          getAPICall={() => {}}
          collapseHeaderCells={collapseContent}
          getSelectedRowTable={handleCallBack}
        />
      </MainCard>
    </Fragment>
  );
};

export default OnlineMarketingActivitiesTypes;
