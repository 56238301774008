/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState,useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import util from 'util'
import { useDispatch, useSelector } from "react-redux";
import { getContracts } from "../../../saga/saga/createdContractSaga"
import { useNavigate,useLocation } from "react-router-dom"; 
import { apiURL } from "../../../services/apiUrlConfig";
import Authorization from "../../../utils/authorization"
import Button from '@mui/material/Button';
import Skeleton from "@mui/material/Skeleton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomButton } from "../../../components/ui/Button";
import { setCondsAndUncondsObj,getAccountsSelected } from "../../../store/reducers/contracts";
import { getContractActionType,selectedViewContractIdActivities } from "../../../store/reducers/common";
import {Childs} from "../../../constants"



const PGELIMIT = 10

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    paddingTop:2,
    paddingBottom:2,
    fontSize: 12,
    fontWeight:100
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingTop:10,
    paddingBottom:9,
    fontWeight:100
  },
}));

const CustomMenu = styled(MenuItem)({
  height:30,
  fontSize:11,
  letterSpacing:0.5
});
const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-input':{
   display:'flex'
 }
 }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCardFilter = styled(Card)(({ theme }) => ({
  '& .MuiCardContent-root': {
    paddingBottom:0 
  }
}));

const headCells = [
  {
    id: "accountname",
    numeric: false,
    disablePadding: true,
    label: "Accounts",
    minWidth: 135,
  },
  {
    id: "country__c",
    numeric: false,
    disablePadding: false,
    label: "Country",
    minWidth: 100,
  },
  {
    id: "unconditionaldiscountpercent",
    numeric: true,
    disablePadding: false,
    label: "Unconditional",
    minWidth: 100,
  },
  {
    id: "conditionaldiscountpercent",
    numeric: true,
    disablePadding: false,
    label: "Conditional",
    minWidth: 95,
  },
  {
    id: "discountpercent",
    numeric: true,
    disablePadding: false,
    label: "Total",
    minWidth: 100,
  }
];


export default function ActivitiesAccount() {
  const [createdContracts, setCreatedContracts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(PGELIMIT);
  const [index, setIndex] = useState(0);
  const [numberOfpages, setNumberOfPages] = useState(0)
  const [expanded, setExpanded] = useState(false);
  const [country, setCountry] = useState('all');
  const { countryList } = useSelector((state) => state.common);
  const [dropdownCountryOptions, setDropdownCountryOptions] = useState([]);

  const getApi = apiURL.ppp.getCreatedContracts+`&pppstage=ACTIVITIES` ?? ''
  const userDetaisl = Authorization.authUser
  const getCreatedList = util.format(getApi, userDetaisl.id)
  const limitIndex = `&index=${index}&limit=${limit}`
  const [fltValidation , setFltValidation ] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createdContractList = useSelector((state) => state.createdContractSaga.contractsList);
  const createdContractStatus = useSelector((state) => state.createdContractSaga.contractsloading);
  // const createdContractError = useSelector((state) => state.createdContractSaga.contractError);
  const location = useLocation();

  const pageIndexChnge = (event, newPage) => {   
    setIndex((newPage-1)*PGELIMIT)
  }

  const clearFilter = () => {
    setCountry("all")
    setFltValidation('')
    dispatch(getContracts(getCreatedList+limitIndex))
  }

  const handleExpandClick = () => { 
    setExpanded(!expanded);
  };

  useEffect(() => {
    setCreatedContracts([])
    dispatch(getContracts(getCreatedList+limitIndex+fltValidation))
  }, [])

  useEffect(() => {
    getCountryDetails();
  }, [countryList]);

  const FilterField =(props)=>{

    const handleChange = (event) => {
      props.selectedValue(event.target.value);
    };
  
    return(
      <Box elevation={0} sx={{display:'flex'}} style={{flexDirection:'column'}}>
        <Typography style={{paddingLeft:0,display:'flex',alignItem:'center',justifyContent:'left',fontSize:'11px',letterSpacing:0.5}} component='label'>
          {props.title}
          </Typography>
        <FormControl sx={{ mr: 2, minWidth: 170 }} size="small">
      <StyledSelect
      name={props.name}
        labelId={props.name}
        id={props.name}
        value={props.value}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        style={{width:170,fontSize:11,display:'flex',letterSpacing:0.5}}
        sx={{display:'flex'}}
      >
        <CustomMenu value={'all'}>
        {props?.allLabel}
        </CustomMenu>
       {props.src?.length > 0&&(props.src).map((itm)=>{
          return <CustomMenu value={itm.value}>{itm.label}</CustomMenu>
       }) }
      </StyledSelect>
    </FormControl>
      </Box>
      )
  }

  
  const countrySelect = (value) =>{
    setCountry(value)
  }
  const applyFilter =() => {
    const countryParam = country !== 'all' ? `&countrycode=${country}` : ''
    const filter = countryParam    
    setFltValidation(filter)
    dispatch(getContracts(getCreatedList+limitIndex+filter))
  }

  const getCountryDetails =()=>{
    if (countryList?.length > 0) {
        const selectOptions = countryList.map((rst) => {
          let obj = { value: rst?.countrycode, label: rst?.countryname };
          return obj;
        });
        const updatedOptions = [ ...selectOptions];
        setDropdownCountryOptions(updatedOptions);
      }
    }
  

  const FilterContentLayout =(props)=>{
    const applyFilter =() => {
      props.applyFilter()
    }
    return <Box sx={{ flexGrow: 1 }}>
        <Grid  style={{height:'100%',width:'100%',border:'0px solid red'}} container  spacing={0} >
           <Grid sx={{pt:2}} DashboardItem xs={12}>
             <Paper elevation={0} sx={{display:'flex'}}>
             <FilterField name={'country'} value={props.country} selectedValue={(value)=>props.countrySelect(value)}  src={props.dropdownCountryOptions} title='Country' allLabel="All country"/>
             {/* <Button style={{borderRadius:25,height:30,width:90,marginTop:20,textTransform:'none',fontSize:12,letterSpacing:0.5}} variant="contained" endIcon={<NavigateNextIcon />}>
              Apply
             </Button> */}
             <CustomButton
                      label="Apply"
                      sx={{
                        marginTop:2,
                        color: "#fff",
                        borderRadius: "28px",
                        backgroundColor: "rgb(0, 145, 223)",
                        fontSize:11,
                        opacity: "1",
                        "&:hover": {
                          backgroundColor: "#0D47A1",
                          // border: "1.35px solid #66B512",
                        },
                        width: "85px",
                        marginLeft: "10px",
                        height: "30px",
                      }}
                      handleClick={()=>applyFilter()}
                      className="cus-btn-arrow"
                      labelClassName={"label"}
                      labelSX={{
                        fontSize: 12,
                        fontWeight: "bold",
                        letterSpacing: 0.3,
                      }}
                    ></CustomButton>
             </Paper>
           </Grid>
        </Grid>
    </Box>
  }
  

  
  useEffect(()=>{
    setCreatedContracts([])
    dispatch(getContracts(getCreatedList+limitIndex+fltValidation)) 
  },[index,limit])

  const ViewAccount = async(obj)=>{
    const objItm = obj.listObj
      dispatch(
        setCondsAndUncondsObj({
          condsAndUncondsObj: {
            conditionalValue:0,
            uncValue:0,
            cDiscount:objItm?.conditionaldiscountpercent||0,
            uncDiscount:0,
            accoundID: objItm.accountid||0,
            totalPYsales:objItm?.pysales||0,
            targetSum:objItm?.targetvalue||0,
            error: {},
            contractId:objItm.id||null,
            contractCountryCode:objItm?.countrycode || '',
            objeArr:obj
          },
        })
      );
      dispatch(getContractActionType({type:'ACTIVITYACCOUNTVIEW',contratId:obj?.id||0,step:22}))
      dispatch(getAccountsSelected({accounts:[...obj.subActivities]}))
      dispatch(selectedViewContractIdActivities({ viewContractID:objItm?.id}));
      const trpath = `${Childs.ACTIVITIES}`
      const titlepath = `${location?.pathname}/${trpath}`
      navigate(trpath,{
        state: {
          title: {...location?.state?.title,[titlepath]:objItm?.accountname} 
        },
      }
      );
  }
  
  useEffect(() => {
    if(!createdContractStatus)
    {
      const contracts = createdContractList?.contracts
      const pagesCount = createdContractList?.count || 0
      const noPages = Math.ceil(pagesCount/PGELIMIT)
      setNumberOfPages(noPages)
      setCreatedContracts(contracts)
    }
  },[createdContractStatus,createdContractList])

  
  const LoadWhile = () => {
    return createdContractStatus ? (
      [...Array(4)].map((item,itemIndex) => {
        return (
          <TableRow key={itemIndex}>
            <TableCell
              colSpan={headCells?.length + 3}
              sx={{ textAlign: "center" }}
              style={{
                borderBottom: "0px solid transparent",
                paddingBottom: 0.5,
                paddingRight: 2,
                paddingLeft: 0,
              }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
              />
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
          {" "}
          No records found!
        </TableCell>
      </TableRow>
    );
    }

  return (
    <Fragment>
    <Box style={{fontSize:15,letterSpacing:0.4,height:40,width:'100%',color:'#323232',fontWeight:600}}>List Of Accounts</Box>
    <Box sx={{width: '100%', typography: 'body1',height:'400' }} style={{marginRight:5,paddingTop:20,paddingRight:20,paddingLeft:20,marginBottom:20,backgroundColor:'white',borderRadius:5}}>
      <Paper elevation={0} sx={{width:"100%"}}>
        <StyledCardFilter elevation={0} sx={{ Width:'100%',fontSize:13 }}>
        <CardContent sx={{p:0,display:'flex'}} style={{paddingBottom:3}}>
         <Box elevation={0} sx={{ display: 'flex', alignItems: 'center'}}>
          <Typography elevation={0} component="div" variant="subtitle2" style={{fontSize:13,letterSpacing:0.3}}>
            Filter
          </Typography>
          <IconButton aria-label="previous">
          <ExpandMoreIcon 
            onClick={handleExpandClick}
          />
          </IconButton>
          </Box>
          {expanded&&<Paper elevation={0} sx={{width:'100%',display:'flex'}} style={{alignItems:'center',justifyContent:'flex-end'}}>
            <Button style={{fontStyle:10}}  sx={{textTransform:'none'}} onClick = {()=>clearFilter()}>Clear Filters</Button>
            </Paper>}
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{p:0}}>
          <FilterContentLayout  country={country}  countrySelect={(value)=>countrySelect(value)} dropdownCountryOptions={dropdownCountryOptions}
          applyFilter={()=>applyFilter()}/>
        </CardContent>
         </Collapse>
        </StyledCardFilter>
      </Paper>
          <Paper elevation={0} sx={{width:"100%"}}>
          <TableContainer style={{overflow: 'auto' ,height:380}}>
          <Table sx={{ maxHeight: 380,width:'100%' }} stickyHeader aria-label="sticky table">
          <TableHead style={{height:40,borderRadius:5,backgroundColor: '#00617F'}}>
          <TableRow style={{borderRadius:5}}>
          {headCells?.length>0&&headCells.map((itm)=>{
            return(<StyledTableCell style={{backgroundColor: '#00617F'}} key={itm?.id} align="left">{itm.label}</StyledTableCell>)
          })}
          <StyledTableCell align="left" style={{backgroundColor: '#00617F'}}>Actions</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {(createdContracts?.length > 0&&headCells?.length > 0) ?(createdContracts.map((row) => {
            return <StyledTableRow key={row.name}>
              {headCells?.length>0&&headCells.map((itm)=>{
              return <StyledTableCell align="left">{row[itm?.id]&&row[itm?.id]+ (itm?.numeric?'%':'')}</StyledTableCell>
              })}
              <StyledTableCell   align="left" style={{textWrap:'nowrap'}}>
               <Button 
               onClick={()=>ViewAccount({accId:row?.accountId,subActivities:row?.activitytargets,listObj:row})} style={{textTransform:'none',width:'70px',height:10,fontSize:11,display:'flex',justifyContent:'left'}} >View</Button>
              </StyledTableCell>
            </StyledTableRow>
          })):<LoadWhile/>}
          </TableBody>
          </Table>
          </TableContainer>
           {numberOfpages>0&&
          <Pagination style={{height:50,width:'100%',display:'flex',justifyContent:'flex-end'}} count={numberOfpages} page={index} onChange={(event,value)=>pageIndexChnge(event,value)} />
           }  
          </Paper>
    </Box>
    </Fragment>

  )
}
