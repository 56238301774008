/* eslint-disable react-hooks/exhaustive-deps */
import React,{ Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Authorization from "../../../../utils/authorization";
import util from "util";
import {resetStatus } from "../../../../saga/saga/activitiesCommonSaga";
import  useActivitiesData from "../ForecastInformation/useActivitiesData"
import Forecastview from '../ForecastInformation/ForecastView';
import { apiURL } from "../../../../services/apiUrlConfig";
import { Box } from '@mui/material';
import dayjs from "dayjs";

export const SubHeaders = (props) => {
    return<Box>
    {/* <span style={{fontSize:11}}>{}<b>{(props?.completedrRecords??0)+'/'+(props?.totalRecords??0)}</b>{' of records completed'}</span> */}
    <span style={{fontSize:11}}>{'Records Completion Status : '}<b>{(props?.completedrRecords??0)+'/'+(props?.totalRecords??0)}</b></span> 
    <span style={{fontSize:11,marginLeft:15}}>{"Submitted By : "}<b>{props?.dueDate?dayjs(props?.dueDate).format("DD/MM/YYYY"):'-'}</b></span>
    </Box>
}
const headCells = [
    {
        id: "pmc",
        numeric: true,
        disablePadding: true,
        label: "Description",
        minWidth: 150,
        isInputBox: false,
        isLast: false,
    
      },
    {
      id: "seed_use_fy_1_mk__c",
      numeric: false,
      disablePadding: true,
      label: "PreviousYear Seed Use",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
    },
    {
      id: "seed_use_fy_mk__c",
      numeric: false,
      disablePadding: false,
      label: "ActualYear Seed Use",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
  
    }
    
  ];

  const prepResponse = (src) => {
    let PrepResp = []
    const marketintelligenceSrc = src?.marketintelligence ?? []
    marketintelligenceSrc && marketintelligenceSrc.forEach(item => {
        let customermarketintelligence = item?.customermarketintelligence
        let description = item?.pmc
        let PreviousYear_SeedUse = customermarketintelligence ? (customermarketintelligence[0]?.seed_use_fy_1_mk__c ?? '-') : '-'
        // item?.seed_use_fy_1_mk__c ?? '-'
        let ActualYear_SeedUse = customermarketintelligence ? (customermarketintelligence[0]?.seed_use_fy_mk__c ?? '-') : '-'
        // item?.seed_use_fy_mk__c ?? '-'

        let PreviousYearPlantedArea = customermarketintelligence ? (customermarketintelligence[0]?.planted_area_fy_2__c ?? '-') : '-'
        let AveragePricePPA = customermarketintelligence ? (customermarketintelligence[0]?.market_list_price_fy_2_mk__c ?? '-') : '-'
        let ActualYearPlantedArea = customermarketintelligence ? (customermarketintelligence[0]?.planted_area_fy__c ?? '-') : '-'
        let AveragePriceAYPA = customermarketintelligence ? (customermarketintelligence[0]?.market_list_price_fy_mk__c ?? '-' ) : '-'
        let NextYearPlantedArea = customermarketintelligence ? (customermarketintelligence[0]?.planted_area_fy_1__c ?? '-') : '-'
        let AveragePriceNPA = customermarketintelligence ? (customermarketintelligence[0]?.market_list_price_fy_1_mk__c ?? '-') : '-'

        let SeedSupplier_1 = customermarketintelligence?(customermarketintelligence[0]?.competitor_1__c ?? '-') : '-'
        let VolShare_1 = customermarketintelligence?(customermarketintelligence[0]?.competitor_share_1__c ?? '-') : '-'
        let AvgPrice_1 = customermarketintelligence?(customermarketintelligence[0]?.average_price_1__c ?? '-') : '-'

        let SeedSupplier_2 = customermarketintelligence?(customermarketintelligence[0]?.competitor_2__c ?? '-'):'-'
        let VolShare_2 = customermarketintelligence?(customermarketintelligence[0]?.competitor_share_2__c ?? '-'):'-'
        let AvgPrice_2 = customermarketintelligence?(customermarketintelligence[0]?.average_price_2__c ?? '-'):'-'

        let SeedSupplier_3 = customermarketintelligence?(customermarketintelligence[0]?.competitor_3__c ?? '-'):'-'
        let VolShare_3 = customermarketintelligence?(customermarketintelligence[0]?.competitor_share_3__c ?? '-'):'-'
        let AvgPrice_3 = customermarketintelligence?(customermarketintelligence[0]?.average_price_3__c ?? '-'):'-'

        PrepResp.push({
            pmc : description,
            seed_use_fy_1_mk__c : PreviousYear_SeedUse,
            seed_use_fy_mk__c : ActualYear_SeedUse,
            planted_area_fy_2__c : PreviousYearPlantedArea,
            market_list_price_fy_2_mk__c : AveragePricePPA,
            planted_area_fy__c : ActualYearPlantedArea,
            market_list_price_fy_mk__c : AveragePriceAYPA,
            planted_area_fy_1__c : NextYearPlantedArea,
            market_list_price_fy_1_mk__c : AveragePriceNPA,

            competitor_1__c : SeedSupplier_1,
            competitor_share_1__c : VolShare_1,
            average_price_1__c : AvgPrice_1,

            competitor_2__c : SeedSupplier_2,
            competitor_share_2__c : VolShare_2,
            average_price_2__c : AvgPrice_2,

            competitor_3__c : SeedSupplier_3,
            competitor_share_3__c : VolShare_3,
            average_price_3__c : AvgPrice_3,
        })

    })
    return PrepResp
  }

  const statColsContent  = [
    {type:'DATABIND', label: "Previous Year Planted Area",  labelid: "planted_area_fy_2__c", id:[{label:'Average Price',labelid:'market_list_price_fy_2_mk__c'}], minWidth: 500, userInteration: false },
    {type:'DATABIND', label: "Actual Year Planted Area",  labelid: "planted_area_fy__c",id:[{label:'Average Price',labelid:'market_list_price_fy_mk__c'}], minWidth: 500, userInteration: false },
    {type:'DATABIND', label: "Next Year Planted Area",  labelid: "planted_area_fy_1__c",id:[{label:'Average Price',labelid:'market_list_price_fy_1_mk__c'}], minWidth: 500, userInteration: false },
    {type:'DATABIND', label: "Seed Supplier 1",  labelid: "competitor_1__c", minWidth: 500,id:[{label:'Average Price',labelid:'competitor_share_1__c'},{label:'Vol Share',labelid:'average_price_1__c'}],  userInteration: false },
    {type:'DATABIND', label: "Seed Supplier 2",  labelid: "competitor_2__c", minWidth: 500,id:[{label:'Average Price',labelid:'competitor_share_2__c'},{label:'Vol Share',labelid:'average_price_2__c'}],   userInteration: false },
    {type:'DATABIND', label: "Seed Supplier 3",  labelid: "competitor_3__c", minWidth: 500,id:[{label:'Average Price',labelid:'competitor_share_3__c'},{label:'Vol Share',labelid:'average_price_3__c'}],   userInteration: false }

  ]

  export default function MarketIntelligence() {
    const [dataSource,setDataSourrce] = useState([])
    const [completedrRecords,setCompletedRecords] = useState(0)
    const [totalRecords,setTotalRecords] = useState(0)
    const [dueDate,setDueDate] = useState('-')

    // eslint-disable-next-line
    const [collapseContent,setCollapseContent] = useState(statColsContent)
    const dispatch = useDispatch();
    const getApi = apiURL.ppp.marketIntelligence ?? "";
    const userDetaisl = Authorization.authUser;
    const getConditionalValues = useSelector(
      (state) => state.contracts.condsAndUncondsObj
    );
    
const activitisApi = util.format(getApi, userDetaisl.id);
// eslint-disable-next-line
const foreCastUrl = activitisApi+`?contractids=[${getConditionalValues?.contractId}]`+'&pppstage=ACTIVITIES';
const [dataApi] = useActivitiesData(foreCastUrl);

useEffect(() => {
    return () => {
      dispatch(resetStatus());
   }
}, [])

useEffect(()=>{
    const resultPrepResp =  prepResponse(dataApi?.body)
    setCompletedRecords(dataApi?.body?.completedrecords)
    setTotalRecords(dataApi?.body.totalrecords)
    setDueDate(dataApi?.body?.duedate)
    setDataSourrce(resultPrepResp)
},[dataApi])  
return(
    <Fragment>
    <SubHeaders completedrRecords={completedrRecords} totalRecords={totalRecords} dueDate={dueDate} />
    <Forecastview pagination={false} multiLine ={false} multiLineSource={'null'} dataSource={dataSource} CollapseContent={collapseContent} headCells={headCells}></Forecastview>
    </Fragment>
)

  }