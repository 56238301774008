import React,{ Fragment } from 'react';
import { Paper, Box } from '@mui/material';
import CollapsibleTable from '../../../../components/ui/CollapsibleTable';
import { useSelector } from "react-redux";

const ForecastView = (props) => {
    const activitiesLoading = useSelector(
        (state) => state.activitiesCommonSaga.activitiesloading
      );
      
return (
        <Fragment>
                  <Box display="flex" flexDirection="column" gap={1.5}>
                  <Box display={"flex"} gap={1} justifyContent="space-between">
                  <Box display={"flex"} gap={1}>
                  </Box>
                  </Box>
                  <Paper elevation={0} sx={{ width: "100%" }}>  
                  <CollapsibleTable
                        statusLoad={props?.dataSource?.length === 0&&activitiesLoading}
                        multiLine={props?.multiLine}
                        multiLineSource={props?.multiLineSource}
                        rows={props?.dataSource}
                        headCells={props?.headCells}
                        getFilterData={() => {}}
                        isPagination={props?.pagination}
                        getAPICall={() => {}}
                        collapseHeaderCells= {props?.CollapseContent ?? []}
                        getSelectedRowTable={() => {}}
                    />
                  </Paper>
                  </Box>
        </Fragment>
    )
  }
  export default ForecastView;
  