import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { downloadFileFromURL } from "../../utils/helper";
import { openSnackbar } from "../../components/ui/Toastr";
import DownloadIcon from "@mui/icons-material/Download";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#388e3c",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.85rem",
  },
  [`&.${tableCellClasses.root}`]: {
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Reports() {
  const [rows, SetRows] = React.useState([]);

  React.useEffect(() => {
    const getEnvData = process.env.REACT_APP_REPORTS;
    const objectValue = getEnvData ? JSON.parse(process.env.REACT_APP_REPORTS) : {};
    let keyAndValueArray = [];
    for (let property in objectValue) {
      let obj = { name: property, url: objectValue[property] };
      keyAndValueArray.push({ ...obj });
    }
    SetRows(keyAndValueArray);
  }, []);

  const handleDownload = (url) => {
    const downloadPath="https://data.heroku.com/dataclips/"
    if (url) {
      downloadFileFromURL(downloadPath + url);
      openSnackbar({ message: "Download has been started", variant: "success" });
    } else {
      openSnackbar({ message: "something went wrong", variant: "warning" });
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Link</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row) => (
              <StyledTableRow key={row?.name}>
                <StyledTableCell>{row?.name}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<DownloadIcon />}
                    onClick={() => handleDownload(row?.url)}
                    sx={{ padding: "2px 9px" }}
                  >
                    download
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                No records found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
